<template>
    <div class="menu-page" v-loadingx="loading">
      <div class="menu-page-title">
        <fm-title title-text="菜单管理" @clickTitleMenu="clickTitleMenu" :title-menus="[{key: 'updateAllFuns', label: '更新全部菜单功能权限'}, {'key': 'update', label: '按配置更新'}]"></fm-title>
      </div>
      <div class="content">
        <div class="left">
          <fm-tree v-loadingx="loading" :nodes="nodes" search is-selected @selected="onSelected" :contextmenu="contextmenu" @contextmenu="onContextmenu"></fm-tree>
        </div>
        <div class="right">
          <FmForm label-width="120px" style="padding-top:20px;">
            <FmFormItem label="菜单名">
              <FmInput v-model="chooseData.name" required v-verifier @verifier="(msg) => $set(verifier, 'name',  msg)" placeholder="菜单名"></FmInput>
            </FmFormItem>
            <FmFormItem label="图标">
              <FmInput v-model="chooseData.icon" v-verifier @verifier="(msg) => $set(verifier, 'icon',  msg)" placeholder="图标"></FmInput>
            </FmFormItem>
            <FmFormItem label="类型">
              <FmSelect v-model="chooseData.type" required v-verifier @verifier="(msg) => $set(verifier, 'type',  msg)" placeholder="类型" block>
                <FmOption v-for="menu in menuTypes" :key="menu.key" :value="menu.key" :label="menu.label"></FmOption>
              </FmSelect>
            </FmFormItem>
            <FmFormItem label="地址">
              <FmInput v-model="chooseData.url" v-verifier @verifier="(msg) => $set(verifier, 'url',  msg)" placeholder="地址"></FmInput>
            </FmFormItem>
            <FmFormItem label="编码">
              <FmInput v-model="chooseData.code" v-verifier @verifier="(msg) => $set(verifier, 'code',  msg)" placeholder="编码"></FmInput>
            </FmFormItem>
            <FmFormItem label="排序">
              <div class="number-input">
                <span class="up" @click="setSort(-1)">上调</span>
                <input :value="chooseData.sort !== undefined && chooseData.sort !== null ? chooseData.sort : sortMax" @blur="sortChange"/>
                <span class="down" @click="setSort(+1)">下调</span>
              </div>
            </FmFormItem>
            <FmFormItem label="父级菜单">
              <FmSelect v-verifier @verifier="(msg) => $set(verifier, 'pid',  msg)" v-model="chooseData.pid" placeholder="父级菜单" block>
                <FmOption v-for="menu in sysMenus" :key="menu.key" :value="menu.key" :label="menu.label"></FmOption>
              </FmSelect>
            </FmFormItem>
            <FmFormItem label="客户端类型">
              <FmSelect required v-verifier @verifier="(msg) => $set(verifier, 'clientType',  msg)" v-model="chooseData.clientType" placeholder="客户端类型" block>
                <FmOption v-for="menu in clientTypes" :key="menu.key" :value="menu.key" :label="menu.label"></FmOption>
              </FmSelect>
            </FmFormItem>
            <FmFormItem label="所属系统">
              <FmSelect required v-verifier @verifier="(msg) => $set(verifier, 'sys',  msg)" v-model="chooseData.sys" placeholder="所属系统" block>
                <FmOption v-for="item in sysList" :key="item.key" :value="item.key" :label="item.label"></FmOption>
              </FmSelect>
            </FmFormItem>
            <!-- <FmFormItem label="所属系统">
              <FmSelect required v-verifier @verifier="(msg) => $set(verifier, 'sys',  msg)" v-model="chooseData.sys" placeholder="所属系统" block>
                <FmOption v-for="menu in sysList" :key="menu.key" :value="menu.key" :label="menu.label"></FmOption>
              </FmSelect>
            </FmFormItem> -->
            <FmFormItem label="功能">
              <div class="menu-funs">
                <div class="fun-item" v-for="item in chooseData.funs" :key="item.id">{{item.name}}</div>
              </div>
            </FmFormItem>
            <FmFormItem>
              <fm-btn v-loadingx="btn_status.save" :disabled="!verifierPass" @click="formSubmit(chooseData)">{{chooseData.id ? '确定修改' : '新增菜单'}}</fm-btn>
            </FmFormItem>
          </FmForm>
        </div>
      </div>
    </div>
</template>

<script>
import {
  menuRequest as request
} from '@/api'

import {
  initMenu
} from '@/config/handle'

import {
  updateFun
} from '@/syslib/update_fun'

import {
  tools
} from '@/fmlib'

import { updateAllFuns } from './menu/lib'

export default {
  components: {
  },
  computed: {
    contextmenu () {
      return [
        {label: '新增', key: 'add'},
        {label: '删除', key: 'del'},
        {label: '功能更新', key: 'update_fun'},
      ].filter(({key}) => this.$authFunsProxy[key]).map(v => v.label)
    },
    verifierPass () {
      return Object.keys(this.verifier).find(v => this.verifier[v] !== '') === undefined
    },
    menuTypes: {
      get () {
        return this.$store.getters.menuTypeList
      }
    },
    sysMenus: {
      get () {
        return [{label: '无', key: 0}, ...this.$store.getters.menuList]
      }
    },
    clientTypes: {
      get () {
        return this.$store.getters.clientTypeList
      }
    },
    sysList () {
      return this.$store.getters.allSysList
    },
    nodes () {
      let fn = (menu) => {
        let clientName = '其他'
        if (menu.clientType === 'web') {
          clientName = '电脑'
        } else if (menu.clientType === 'wap') {
          clientName = '手机端'
        } else if (menu.clientType === 'pda') {
          clientName = 'PDA设备'
        } else if (menu.clientType === 'app') {
          clientName = 'APP'
        }
        let sys = this.sysList.find(v => v.key === menu.sys)
        return {
          title: menu.name + ' - ' + clientName + (sys ? '-' + sys.label : ''),
          data: menu
        }
      }
      let result = this.dataList.filter(v => v.pid === 0).map(fn)
      let each = (nodes) => {
        nodes.forEach(node => {
          node.children = this.dataList.filter(v => v.pid === node.data.id).map(fn)
          each(node.children)
        })
      }
      each(result)

      return result
    },
    sorts () {
      return this.dataList.map(v => ~~v.sort)
    },
    sortMax () {
      return Math.max(...this.sorts)
    },
    sortMin () {
      return Math.min(...this.sorts)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async clickTitleMenu (key) {
      this.loading = true
      if (key === 'update') {
        await initMenu()
      } else if (key === 'updateAllFuns') {
        await this.updateAllFuns()
      }
      this.loading = false
      this.loadData()
    },
    async updateAllFuns () {
      const confirm = await this.$confirm({ title: '确定更新全部吗？' })
      if (confirm) {
        const res = await updateAllFuns(this.dataList)
        console.log(res)
        setTimeout(() => {
          this.$Modal.info({
            title: '更新结果',
            render: (h) => {
              return h('div', res.map(v => {
                return h('Alert', {
                  props: { type: v.type }
                }, v.text)
              }))
            }
          })
        }, 500)
      }
    },
    async onContextmenu (menu, data) {
      if (menu === '新增') {
        this.chooseData = {
          pid: data.data.data.id
        }
      } else if (menu === '删除') {
        this.delData(data.data.data)
      } else if (menu === '功能更新') {
        await updateFun([data.data.data])
        this.loadData()
      }
    },
    onSelected (menu) {
      this.$nextTick(() => {
        this.sourceData = menu.data.data
        this.chooseData = Object.assign({}, this.sourceData)
      })
    },
    async delData (data) {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除菜单 ' + data.name + ' 吗?'})
      if (result) {
        this.btn_status.del = true
        request.del(data.id).then(() => {
          this.btn_status.del = false
          this.$notice.info({
            title: '系统提示',
            desc: '菜单已删除'
          })
          this.loadData()
        })
      }
    },
    async formSubmit (data) {
      if (data.id && data.pid && data.pid === data.id) {
        this.$notice.warning({
          title: '系统提示',
          desc: '父级菜单不能选自己。'
        })
        return
      }
      // 当菜单无排序时将菜单调至最后
      if (data.sort === undefined || data.sort === null) {
        data.sort = this.sortMax + 1
      }
      this.btn_status.save = true
      if (this.chooseData && this.chooseData.id) {
        await request.update(this.chooseData.id, data)
        if (this.sourceData.url !== this.chooseData.url) {
          await updateFun([this.chooseData])
        }
        this.$notice.success({
          title: '系统提示',
          desc: '菜单修改完成'
        })
        this.btn_status.save = false
        this.loadData()
      } else {
        let id = await request.add(data)
        await updateFun([Object.assign({}, data, {id: id})])
        this.$notice.success({
          title: '系统提示',
          desc: '菜单新增完成'
        })
        this.btn_status.save = false
        this.loadData()
      }
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      this.loading = true
      let parm = {}
      request.get(parm).then((data) => {
        let newData = tools.treeToList(data, 'children')
        newData.forEach(v => v.funIds = v.funs.map(v2 => v2.id))
        this.$store.dispatch('setMenuList', newData)
        this.dataList = newData
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    setSort (opr, t = false) {
      if (this.chooseData) {
        const sort = t ? opr : (this.chooseData.sort === null || this.chooseData.sort === undefined ?  this.sortMax : this.chooseData.sort) + opr
        if (sort >= 0 && sort >= this.sortMin - 1 && sort <= this.sortMax + 1) {
          this.$set(this.chooseData, 'sort', sort)
        } else {
          this.$notice.warning('无效的排序取值范围')
          this.$set(this.chooseData, 'sort', null)
        }
      }
    },
    sortChange ({target}) {
      let sort = parseInt(target.value)
      if (isNaN(sort) || this.chooseData.sort === undefined || this.chooseData.sort === null) {
        this.$set(this.chooseData, 'sort', this.sortMax)
      } else {
        this.setSort(sort, true)
      }
    }
  },
  data () {
    return {
      verifier: {},
      dataList: [],
      noteText: '',
      loading: true,
      chooseData: {},
      sourceData: {},
      chooseDataApi: null,
      notChooseApis: [],
      chooseApis: [],
      openDialog: false,
      btn_status: {
        del: false,
        save: false
      },
      apis: [],
      openDialogApi: false
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/values.less';
.title-sort {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
.modal-c {
  height: 30rem;
  width: 100%;
  display: flex;
  .t-c {
    flex: 1;
  }
  .api-item {
    margin: 0 1rem;
    border: @size-border solid @color-border;
    border-radius: @size-border-radius;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.menu-funs {
  display: flex;
  flex-wrap: wrap;
  color: #515a6e;
  .fun-item {
    margin-right: 20px;
    font-size: 12px;
    line-height: 20px;
  }
}
  .menu-page {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    margin: 1rem;
    .menu-page-title {
      background: #fff;
      margin-bottom: 0.5rem;
    }
  }
  .sign-page-top-1 {
    margin-bottom: 1rem;
  }
  .content {
    width: 100%;
    height: calc(100% - 48px - 0.5rem);
    display: flex;
    .left, .right {
      background-color: #FFF;
      border-radius: 5px;
      padding: 10px;
      height: calc(100% - 20px);
      max-height: calc(100% - 20px);
      overflow-y: auto;
    }
    .left {
      padding-bottom: 100px;
      width: 20%;
      min-width: 20%;
    }
    .right {
      margin-left: 0.5rem;
      flex: 1;
    }
  }
  .number-input {
    border: 1px solid #dcdddd;
    border-radius: 4px;
    display: flex;
    &, input, span {
      line-height: 1.9rem;
      font-size: 1rem;
      color: #515a6e;
    }
    input, span {
      display: inline-block;
      flex: 1;
      border: none;
      text-align: center;
    }
    span {
      &:first-of-type, &:last-of-type {
        background-color: #dcdddd;
        width: 20%;
        cursor: pointer;
      }
    }
  }
</style>