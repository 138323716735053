import router from '@/router'
import { routerFunMap } from '@/syslib/update_fun'
import { request } from '@/api'

export function getRouteByName(name, routes) {
  for (let route of routes) {
    if (route.name === name) {
      return route
    }
    if (route.children && route.children.length) {
      let subRoute = getRouteByName(name, route.children)
      if (subRoute) {
        return subRoute
      }
    }
  }
  return null
}

export async function updateFun(menu) {
  if (menu.type !== 'sys_page') {
    throw new Error('请选择系统页面')
  }
  if (!menu.url) {
    throw new Error('请输入地址')
  }

  if (menu.clientType === 'web') {
    let route = getRouteByName(menu.url, router.getRoutes())
    if (!route) {
      throw new Error(menu.url + '路由不存在')
    }
  }
  if (!routerFunMap[menu.url]) {
    throw new Error(menu.url + '路由未指定功能权限')
  }

  let menuFunKeys = menu.funs ? menu.funs.map(v => v.funKey) : []
  let routerFunKeys = []

  for (let routeFun of routerFunMap[menu.url]) {
    let routefFunKey = routeFun.funKey
    routerFunKeys.push(routefFunKey)

    let data = {
      menuIds: [menu.id],
      funKey: routefFunKey,
      name: routeFun.name,
      apis: routeFun.apis
    }

    if (!menuFunKeys.includes(routefFunKey)) {
      console.log('新增', routefFunKey, data)
      await request('/ucenter/system/funs', 'POST', data)
    } else {
      let menuFun = menu.funs ? menu.funs.find(v => v.funKey === routefFunKey) : null
      console.log('更新', routefFunKey, data)
      if (menuFun) {
        await request('/ucenter/system/funs/' + menuFun.id, 'PUT', data)
      } else {
        throw new Error('更新失败:' + routefFunKey + '菜单功能不存在，请刷新后重试')
      }
    }
  }

  for (let menuFunKey of menuFunKeys) {
    if (!routerFunKeys.includes(menuFunKey)) {
      console.log('删除', menuFunKey)
      let menuFun = menu.funs ? menu.funs.find(v => v.funKey === menuFunKey) : null
      if (menuFun) {
        await request('/ucenter/system/funs/' + menuFun.id, 'DELETE')
      } else {
        throw new Error('删除失败:' + menuFunKey + '菜单功能不存在，请刷新后重试')
      }
    }
  }
}

export async function updateAllFuns (dataList) {
  let menu
  let success = 0
  let vnodes = []
  try {
    for (menu of dataList) {
      if (menu.type === 'sys_page' && menu.url) {
        await updateFun(menu)
        success++
      }
    }
    vnodes.push({
      type: 'success',
      text: '全部更新成功'
    })
  } catch (e) {
    if (success) {
      vnodes.push({
        type: 'success',
        text: '已更新' + success + "个菜单"
      })
    }
    if (menu) {
      vnodes.push({
        type: 'warning',
        text: '菜单更新失败: ' + menu.name
      })
    }
    vnodes.push({
      type: 'error',
      text: e.message
    })
  }
  return vnodes
}